import Loader from 'components/Loader';
import ROUTES from 'constants/routes';
import useUser from 'hooks/useUser';
import DashboardLayout from 'layouts/DashboardLayout';
import OnboardLayout from 'layouts/OnboardLayout';
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Login = lazy(() => import('pages/Auth/Login'));
const Register = lazy(() => import('pages/Auth/Register'));
const RecoverPassword = lazy(() => import('pages/Auth/RecoverPassword'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Trades = lazy(() => import('pages/Trades'));
const Requisitions = lazy(() => import('pages/Requisitions'));
const Sales = lazy(() => import('pages/Sales'));
const Expenses = lazy(() => import('pages/Expenses'));
const Retailers = lazy(() => import('pages/Retail/Retailers'));
const Profile = lazy(() => import('pages/Profile'));
const Notifications = lazy(() => import('pages/Notifications'));
const FinanceRequests = lazy(() => import('pages/Finance/Requests'));
const Storage = lazy(() => import('pages/Retail/Storage'));
const Logistics = lazy(() => import('pages/Retail/Logistics'));
const Inspection = lazy(() => import('pages/Retail/Inspection'));
const WholeSaleRequests = lazy(() => import('pages/Retail/Requests'));
const OnlineSales = lazy(() => import('pages/OnlineSales'));
const ContributionMargin = lazy(() => import('pages/ContributionMargin'));
const OperatingIncome = lazy(() => import('pages/OperatingIncome'));
const Inventory = lazy(() => import('pages/Inventory'));

const App = () => {
    const { hasAllAccess, isWholeSale, isTrade, isFinance, isWholeSaleManager, isSuperAdmin, user } = useUser();

    return (
        <Suspense
            fallback={
                <div className="h-screen flex items-center justify-center">
                    <Loader />
                </div>
            }
        >
            <Routes>
                <Route element={<OnboardLayout />}>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.REGISTER} element={<Register />} />
                    <Route path={ROUTES.RECOVER} element={<RecoverPassword />} />
                </Route>

                <Route element={<DashboardLayout />}>
                    <Route path={ROUTES.HOMEPAGE} element={<Dashboard />} />
                    {user?.role && (
                        <>
                            {(isTrade || hasAllAccess) && <Route path={ROUTES.TRADES} element={<Trades />} />}
                            {(isWholeSale || hasAllAccess) && <Route path={ROUTES.SALES} element={<Sales />} />}
                            <Route path={ROUTES.REQUISITIONS} element={<Requisitions />} />
                            <Route path={ROUTES.EXPENSES} element={<Expenses />} /> {}
                            {(isWholeSale || hasAllAccess) && <Route path={ROUTES.RETAILERS} element={<Retailers />} />}
                            {(isSuperAdmin || isWholeSaleManager) && (
                                <Route path={ROUTES.RETAILERS_REQUESTS} element={<WholeSaleRequests />} />
                            )}
                            {hasAllAccess && (
                                <>
                                    <Route path={ROUTES.STORAGE} element={<Storage />} />
                                    <Route path={ROUTES.LOGISTICS} element={<Logistics />} />
                                    <Route path={ROUTES.INSPECTIONS} element={<Inspection />} />
                                    <Route path={ROUTES.ONLINE} element={<OnlineSales />} />
                                </>
                            )}
                            {(isSuperAdmin || isFinance) && (
                                <>
                                    <Route path={ROUTES.CONTRIBUTION_MARGIN} element={<ContributionMargin />} />
                                    <Route path={ROUTES.OPERATING_INCOME} element={<OperatingIncome />} />{' '}
                                    <Route path={ROUTES.INVENTORY} element={<Inventory />} />
                                    <Route path={ROUTES.FINANCE_REQUISITIONS} element={<FinanceRequests />} />
                                </>
                            )}
                            <Route path={ROUTES.PROFILE} element={<Profile />} />
                            <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
                        </>
                    )}
                </Route>

                <Route path={'*'} element={<Navigate to={ROUTES.HOMEPAGE} />} />
            </Routes>
        </Suspense>
    );
};

export default App;
